module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/andrea/Siti/bagnoeuropa71.it/htdocs/static/src/locales","languages":["it","en","fr","de"],"defaultLanguage":"it","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Il Bagno Europa, 71 si trova a Cesenatico sulla spiaggia di Levante, a due passi dal molo.","short_name":"Landing","start_url":"/","background_color":"#187589","theme_color":"#187589","display":"standalone","icon":"src/assets/img/website-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
